<template>
  <div class="about">
    <div class="banner">
      <img width="100%" src="../assets/矩形 9.png" alt="" />
    </div>
    <div class="title">
      <div class="h1">About Us</div>
    </div>
    <div class="nav">
      <div class="content" id="OurBackground">
        <div class="left">
          <h2>Our Background</h2>
          <p>
            Hong Kong Trust Capital Management Limited was found in Hong Kong in
            2015. The company is a licensed Trustee registered under Ordinance
            78(1), Chapter 29 of Law of Hong Kong The license is issued by the
            Treasury of the Hong Kong SAR Government. The company is under
            direct supervision by the Secretary of Finance of the Hong Kong SAR
            Government.
          </p>
          <p>
            The company distinguishes itself by focusing on diversified and
            professional trustee service of high quality to clients in Asia
            Pacific in close collaboration with multinational banks, financial
            institutes and other regional institutes, international law and
            accountant firms. Our service scope ranges from custody of assets,
            family inheritance, capital growth, setting up new corporate or
            private trusts in Asia Pacific to merge and acquisition.
          </p>
        </div>
        <div class="right">
          <img src="../assets/About1.png" alt="" />
        </div>
      </div>
      <div class="content" id="OurAdvantages">
        <div class="left">
          <h2>Our Advantages</h2>
          <p class="headline">Professional Trust Service</p>
          <p>◆ Customized trust plans</p>
          <p>◆ Professional team with profound experience</p>
          <p>◆ Service provided with high standard and full integrity</p>
          <p class="headline">Wealth Management with Flexibility</p>
          <p>
            ◆ Service provided with collaboration with multinational investment
            banks
          </p>
          <p>◆ Various global investment plans at the choice of customer</p>
          <p>
            ◆ Professional guidance to customers in selection of suitable
            investment plans
          </p>
          <p>◆ Investment plans offer highly competitive return</p>
        </div>
        <div class="right">
          <img src="../assets/About2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { goAnchor, GetQueryString } from "../js/common";
export default {
  data() {
    return {};
  },
  mounted() {
    let maodian = this.GetQueryString("maodian"); //进入页面，如果带有锚点参数，则跳转至锚点地方，参数值就是id名
    console.info("*****", maodian);
    if (maodian) {
      this.goAnchor("#" + maodian);
    }
  },
  methods: {
    goAnchor,
    GetQueryString,
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100vw;
  height: 994px;
  background: url("../assets/banner6.png") no-repeat;
  background-size: cover;
}
.title {
  color: #fff;
  display: flex;
  width: 100vw;
  height: 994px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
  top: 0;
  font-size: 86px;
}
.nav {
  width: 80vw;
  margin: 0 auto;
  margin-top: 180px;
  text-align: left;
  .content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 130px;
    border-bottom: 1px solid #000;
    margin-top: 130px;
    h2 {
      font-size: 54px;
      font-weight: 500;
      margin: 0px;
      margin-bottom: 60px;
    }
    p {
      font-size: 26px;
      font-weight: 500;
      margin: 0px;
    }
    .left {
      width: 47%;
      .headline {
        font-size: 28px;
        font-weight: 500;
        line-height: 56px;
      }
    }
    .right {
      width: 51%;
      /*       img {
      } */
    }
  }
}
</style>
